var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","lg11":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-between":"","py-2":""}},_vm._l((_vm.navItems),function(item,i){return _c('v-flex',{key:i,attrs:{"px-2":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'/home?content=' + item.name}},[_c('v-btn',{style:({
                border:
                  _vm.$route.query.content == item.name
                    ? '1px solid #ffe200'
                    : !_vm.$route.query.content && item.name == 'Top Data'
                    ? '1px solid #ffe200'
                    : 'none',
              }),attrs:{"block":"","text":"","ripple":false}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"14px","color":"#343434","text-transform":"none"}},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)],1)}),1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.$route.query.content == 'Top Data' || !_vm.$route.query.content)?_c('TopData'):_vm._e(),(_vm.$route.query.content == 'Area of Focus')?_c('AreaofFocus'):_vm._e(),(_vm.$route.query.content == 'Products And Services')?_c('ProductsAndServices'):_vm._e(),(_vm.$route.query.content == 'Clients')?_c('Clients'):_vm._e(),(_vm.$route.query.content == 'Testimonials')?_c('Testimonials'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }