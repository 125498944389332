<template>
  <div>
    <PageLoader />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pb-4 px-3>
      <v-flex xs12 text-left>
        <span class="homeTop"> Area of Focus </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="home.areaOfFocus.topCaption"
                  label="Top Caption of the Area of Focus"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  outlined
                  class="homeText"
                  dense
                  v-model="home.areaOfFocus.title"
                  label="Title of the Area of Focus"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="home.areaOfFocus.caption"
                  label="Caption of the Area of Focus"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="black"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                >
                  <span style="color: #ffffff">Save</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap py-5>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout justify-center wrap>
          <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
            <AreaofFocusMultiple />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
import AreaofFocusMultiple from "./areaofFocusMultiple";
export default {
  components: {
    AreaofFocusMultiple,
  },
  data() {
    return {
      home: {
        areaOfFocus: { topCaption: null, title: null, caption: null },
      },
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      imageFiles: [],
      id: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    validateInput() {
      // if (!this.home.areaOfFocus.topCaption) {
      //   this.msg = "Please Provide Top caption of Area of Focus";
      //   this.showSnackBar = true;
      //   return;
      // } 
      // else
       if (!this.home.areaOfFocus.title) {
        this.msg = "Please Provide Title of Area of Focus";
        this.showSnackBar = true;
        return;
      } else if (!this.home.areaOfFocus.caption) {
        this.msg = "Please Provide Caption of Area of Focus";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    getData() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/home/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.home = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      axios({
        method: "post",
        url: "/home/set",
        data: this.home,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = " Updated Successully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>