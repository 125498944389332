<template>
  <div>
    <PageLoader />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pb-4 px-3>
      <v-flex xs12 text-left>
        <span class="homeTop"> Products and Services</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12> </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="home.productsAndServices.topCaption"
                  label="Top Caption of the Products and Services"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="home.productsAndServices.title"
                  label="Title of the Products and Services"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="home.productsAndServices.caption"
                  label="Caption of the Products and Services"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="home.productsAndServices.leftTextTitle"
                  label="Left Text Title of the Products and Services"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12>
                <v-textarea
                  class="homeText"
                  outlined
                  dense
                  v-model="home.productsAndServices.leftTextDescription"
                  label="Left Text Description of the Products and Services"
                ></v-textarea>
              </v-flex> -->
               <v-flex xs12 pb-5>
                  <span class="homeText">Left Text Description of the Products and Services</span>
                  <div id="app">
                    <vue-editor v-model="home.productsAndServices.leftTextDescription"></vue-editor>
                  </div>
                </v-flex>
              <!-- <v-flex md12 xs12 pb-5>
                <ImageComp
                  class="homeText"
                  @stepper="winStepper"
                  :singleImage="home.productsAndServices.image"
                  :height="'941'"
                  :width="'1517'"
                  :heading="'Upload Product Top Image'"
                  :componentType="'product top image'"
                />
              </v-flex> -->
               <v-flex xs12>
                  <img
                    v-if="imagePreview"
                    :src="imagePreview"
                    v-show="showPreview"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  />
                  <img
                    v-else
                    :src="mediaURL + home.productsAndServices.image"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  />
                </v-flex>
                <v-flex xs12 sm7 md5 lg4>
                  <v-btn
                    width="180px"
                    block
                    tile
                    depressed
                    :ripple="false"
                    class="text-capitalize"
                    color="#F6F5FF"
                    @click="$refs.showImage.click()"
                  >
                    <v-layout justify-center wrap>
                      <v-flex xs2 sm2 md2 text-left>
                        <v-icon style="color: #000000">mdi-upload</v-icon>
                      </v-flex>
                      <v-flex xs10 sm8 md8>
                        <span
                          style="
                            font-family: poppinsRegular;
                            color: #020202;
                            font-size: 12px;
                          "
                          >Upload Product Top Image
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-btn>
                  <input
                    v-show="false"
                    accept="image/*"
                    id="file"
                    ref="showImage"
                    multiple="multiple"
                    type="file"
                    @change="showImage"
                  />
                </v-flex>
              <v-flex md12 xs12 pb-5>
                <v-flex xs12>
                  <img
                    v-if="imagePreviewCover"
                    :src="imagePreviewCover"
                    v-show="showPreviewCover"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  />
                  <img
                    v-else
                    :src="mediaURL + home.productsAndServices.rightImage"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  />
                </v-flex>
                <v-flex xs12 sm7 md5 lg4>
                  <v-btn
                    width="180px"
                    block
                    tile
                    depressed
                    :ripple="false"
                    class="text-capitalize"
                    color="#F6F5FF"
                    @click="$refs.showImageCover.click()"
                  >
                    <v-layout justify-center wrap>
                      <v-flex xs2 sm2 md2 text-left>
                        <v-icon style="color: #000000">mdi-upload</v-icon>
                      </v-flex>
                      <v-flex xs10 sm8 md8>
                        <span
                          style="
                            font-family: poppinsRegular;
                            color: #020202;
                            font-size: 12px;
                          "
                          >Upload Product Right Image
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-btn>
                  <input
                    v-show="false"
                    accept="image/*"
                    id="file"
                    ref="showImageCover"
                    multiple="multiple"
                    type="file"
                    @change="showImageCover"
                  />
                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="black"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                >
                  <span style="color: #ffffff">Save</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import store from "./../../store";
import { VueEditor } from "vue2-editor";
// import ImageComp from "@/components/Common/singleImage";
export default {
  components: {
    // ImageComp,
    VueEditor,
  },
  data() {
    return {
      home: {
        productsAndServices: {
          topCaption: null,
          title: null,
          caption: null,
          leftTextTitle: null,
          leftTextDescription: null,
        },
      },
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      imagePreview: "",
      showPreview: false,
      file: null,
      imagePreviewCover: "",
      showPreviewCover: false,
      file1: null,
      coverImageArray: [],
      formDataTop: new FormData(),
      formDataRight: new FormData(),
      selectedImage: null,
      topimageFile: null,
      producttopimagefile: null,
      productrightimagefile: null,
      imageFiles: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "product top image") {
        this.producttopimagefile = window_data.selectedFiles;
      } else if (window_data.type == "product right image") {
        this.productrightimagefile = window_data.selectedFiles;
      }
    },
    validateInput() {
      if (!this.home.productsAndServices.topCaption) {
        this.msg = "Please Provide Top Caption of the Products and Services";
        this.showSnackBar = true;
        return;
      } else if (!this.home.productsAndServices.title) {
        this.msg = "Please Provide Title of the Products and Services";
        this.showSnackBar = true;
        return;
      } else if (!this.home.productsAndServices.caption) {
        this.msg = "Please Provide Caption of the Products and Services";
        this.showSnackBar = true;
        return;
      } else if (!this.home.productsAndServices.leftTextTitle) {
        this.msg =
          "Please Provide Left text title of the Products and Services";
        this.showSnackBar = true;
        return;
      } else if (!this.home.productsAndServices.leftTextDescription) {
        this.msg =
          "Please Provide Left Text Description of the Products and Services";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    getData() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/home/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.home = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      axios({
        method: "post",
        url: "/home/set",
        data: this.home,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = " Updated Successully";
             this.getData();
            if (this.imagePreview) {
              this.uploadImageTop();
            }
            if (this.imagePreviewCover) {
              this.uploadImageRight();
            }
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
     showImageCover(e) {
      this.file1 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImageCover.value = null;
        this.imagePreviewCover = e.target.result;
      };
      if (this.file1.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.showPreviewCover = true;
      if (this.file1) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file1.name)) {
          reader.readAsDataURL(this.file1);
        }
      }
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImage.value = null;
        this.imagePreview = e.target.result;
      };
      if (this.file.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImageTop() {
      this.appLoading = true;
      this.formDataTop.append("image", this.file);
      this.formDataTop.append("type", "product top image");
      axios({
        method: "POST",
        url: "/home/image",
        data: this.formDataTop,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = " Upload Successfully";
            this.showSnackBar = true;
             this.getData();
          } else {
            this.msg = "Can't Upload Cover Image.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    uploadImageRight() {
      this.appLoading = true;
      this.formDataRight.append("type", "product right image");
      this.formDataRight.append("image", this.file1);
      axios({
        method: "POST",
        url: "/home/image",
        data: this.formDataRight,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = " Upload Successfully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = "Can't Upload Cover Image.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
</style>