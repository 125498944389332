<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 lg11>
        <v-layout wrap justify-space-between py-2>
          <v-flex v-for="(item, i) in navItems" :key="i" px-2>
            <router-link
              style="text-decoration: none"
              :to="'/home?content=' + item.name"
            >
              <v-btn
                block
                text
                :ripple="false"
                :style="{
                  border:
                    $route.query.content == item.name
                      ? '1px solid #ffe200'
                      : !$route.query.content && item.name == 'Top Data'
                      ? '1px solid #ffe200'
                      : 'none',
                }"
              >
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 14px;
                    color: #343434;
                    text-transform: none;
                  "
                >
                  {{ item.name }}
                </span>
              </v-btn>
            </router-link>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <TopData
          v-if="$route.query.content == 'Top Data' || !$route.query.content"
        />
        <AreaofFocus v-if="$route.query.content == 'Area of Focus'" />
        <ProductsAndServices
          v-if="$route.query.content == 'Products And Services'"
        />
        <Clients v-if="$route.query.content == 'Clients'" />
        <Testimonials v-if="$route.query.content == 'Testimonials'" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import TopData from "./topData";
import AreaofFocus from "./areaofFocus";
import ProductsAndServices from "./productsAndservices";
import Clients from "./clients";
import Testimonials from "./testimonials";

export default {
  components: {
    TopData,
    AreaofFocus,
    ProductsAndServices,
    Clients,
    Testimonials,
  },
  data() {
    return {
      navItems: [
        { name: "Top Data" },
        { name: "Area of Focus" },
        { name: "Products And Services" },
        { name: "Clients" },
        { name: "Testimonials" },
      ],
    };
  },
};
</script>